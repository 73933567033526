<template>
  <div class="body-main">
    <div class="main">
      <div>
          <p class="ql-editor" v-html="resInfo"></p>
      </div>
    </div>
  </div>
</template>

<script>
import { searchByType } from "@/api/index";
export default {
  name: "featuredTickets",
  created() {
    this.getStaticHtml();
  },
  data() {
    return {
      resInfo: ""
    };
  },
  methods: {
    getStaticHtml() {
      searchByType({ type_bn: "s_page_type_003" })
        .then(res => {
          this.resInfo = res.data[0].page_val;
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.body-main {
  width: 100%;
  display: flex;
  background: rgba(245, 245, 245, 1);
  justify-content: center;
  .main {
    width: 1200px;
  }
}
</style>
